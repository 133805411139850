export default ({ items }) => {
  return {
    items: items?.map(({ data: item }) => ({
      id: item.id,
      title: item.title,
      subtitle: `<strong>${item.subtitle}</strong>`,
      questions: item.questions?.map((question) => ({
        answer: question.answer,
        question: question.question,
      })),
    })),
  };
};
